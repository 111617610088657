// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$background-color: #59A400;
$accent-color: #FF9900;

@import url(https://fonts.googleapis.com/css?family=Ubuntu:700);

body {
  background-color: $background-color;
}

.frame {
  max-width: 1400px;
  display: table;
  margin: 0 auto 50px;
}

aside {
  width: 290px;
  display: table-cell;
  position: relative;
  z-index: 0;
  li {
    list-style-type: none;
    text-align: right;
    margin-bottom: 15px;
    position: absolute;
    right: -5px;
    text-transform: uppercase;
  }

  li a {
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }

  li a:hover,
  li a:focus {
    text-decoration: none;
    font-weight: bold;
  }
}



aside > ul > li {
  font-size: 14pt;
  padding: 5px 40px 5px 15px;
  background-color: $accent-color;
  -ms-transform: rotate(6deg); /* IE 9 */
  -webkit-transform: rotate(6deg); /* Chrome, Safari, Opera */
  transform: rotate(6deg);
}

aside > ul > li a {
  color: white;
}

aside > ul > li a:hover,
aside > ul > li a:focus {
  color: $background-color;
}

aside > ul > ul > li {
  font-size: 12pt;
  padding: 5px 20px 5px 15px;
  background-color: lighten($background-color, 5%);
  -ms-transform: rotate(-2deg); /* IE 9 */
  -webkit-transform: rotate(-2deg); /* Chrome, Safari, Opera */
  transform: rotate(-2deg);
}

aside > ul > ul > li a {
  color: $accent-color;
}

aside > ul > ul > li a:hover,
aside > ul > ul > li a:focus {
  color: white;
}

#link1 {
  top: 160px;
}

#link2 {
  top: 210px;
}

#link3 {
  top: 260px;
}

#link4 {
  top: 310px;
}

#link5 {
  top: 360px;
}

#link6 {
  top: 410px;
}

#link7 {
  top: 460px;
}

#link8 {
  top: 510px;
}

#link9 {
  top: 560px;
}

#link10 {
  top: 610px;
}

#link11 {
  top: 660px;
}

#link12 {
  top: 710px;
}

#link13 {
  top: 760px;
}

#link14 {
  top: 810px;
}

main {
  display: table-cell;
  max-width: 1000px;
  position: relative;
  z-index: 1;
}

section {
  background-color: white;
  padding: 40px;
  min-height: 750px;
}

header.left {
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: $background-color;
  color: $accent-color;
}

header.top {
  background-color: $background-color;
  color: $accent-color;
  text-align: right;
  padding-bottom: 20px;
  padding-top: 20px;
}

header.top a {
  color: $accent-color;
}

header.top a:hover {
  color: white;
}

header.mobile {
  color: $accent-color;
}

.logo {
  font-family: Ubuntu;
  padding-top: 55px;
  font-size: 50pt;
  font-weight: bold;
  line-height: 0px;
  -ms-transform: rotate(6deg); /* IE 9 */
  -webkit-transform: rotate(6deg); /* Chrome, Safari, Opera */
  transform: rotate(6deg);
  margin-bottom: 20px;

  small {
    font-size: 8pt;
    position: relative;
    top: -15px;
  }
}

.mobilelogo {
  font-family: Ubuntu;
  padding-top: 15px;
  margin-left: 12px;
  font-size: 40pt;
  font-weight: bold;
}

.mobile-menu-toggle {
  position: absolute;
  right: 10px;
  top: 20px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background: transparent none;
  border: 1px solid $accent-color;
  border-radius: 4px;
  &:focus, &:hover {
    background-color: $accent-color
  }
  .icon-bar {
    background-color: #fff;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px
  }
  .icon-bar + .icon-bar {
    margin-top: 4px
  }
}

.mobile-menu {
  background-color: $accent-color;
  li {
    list-style: none inside;
    color: white;
    text-align: center;
    font-size: 14pt;
    font-weight: bold;
  }
  li a {
    color: $background-color;
  }
  li.sub a {
    color: white;
  }
  li a:hover,
  li a:focus {
    text-decoration: none;
  }
}

article {
  font-size: 14pt;
  margin-bottom: 80px;
  h2 {
    color: $accent-color;
    font-weight: bold;
  }

  h3 {
    color: $background-color;
    font-weight: bold;
  }
  img{
    max-width:80%;
    max-height:600px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  h2 a{
    color: $accent-color;
    &:hover,
    &:focus{
      color:$background-color;
      text-decoration: none;
    }
  }
}

.btn-gras {
  background-color: $background-color;
  margin: 2px;
  &:hover {
    background-color: $accent-color;
  }
}

footer {
  background-color: darken($background-color, 10%);
  color: $accent-color;
  padding-top: 50px;
  div {
    text-align: center;
    font-weight: bold;
    font-size: 15pt;
  }

  a {
    color: $accent-color;
  }

  a:hover {
    color: white;
  }
}








